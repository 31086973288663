import React from 'react';
import {Button, Row, Col } from 'react-bootstrap';

import styled, { ThemeProvider } from 'styled-components';
import Profile from '../Profile/Profile';

import theme from "../../Config/Theme";
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../../Components/Loading/Loading';
// import ClientSurveyStats from '../ClientSurveyStats/ClientSurveyStats';
// import Anniversaries from './Widgets/Anniversaries';
// import Budgets from '../Budgets/Budgets';


const Div = styled.div `
    height: 100vh;
    padding: 2em;

    .widgets{
      padding: 2em;
      color: black;
      background-color: rgba(255,255,255, 0.85);
    }

    .widget-button{
    background-color: rgba(30, 30, 45, 0.5);
    border: 1px solid ${(props) => props.theme.colors.orange};
    border-radius: .25em;
    margin-top: 3em;
    padding: 4em;
  }

`

const StyledButton = styled(Button) `
    margin: 0;
    padding:0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.medium_blue};
    background-color: rgba(255,255,255, 0.9);
    border: 2px solid ${props => props.theme.colors.medium_blue};
    border-radius: .5rem;
    &:hover{
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.medium_blue};
        border: 2px solid ${props => props.theme.colors.orange};
    }

    div{
        margin-top: 1em;
        font-size: .75em;
    }
    

`

const Dashboard = () => {
  const { isLoading, error } = useAuth0();
  const {
    isAuthenticated,
    logout
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
  
  if (error) {
    logoutWithRedirect();
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return (
          
          <ThemeProvider theme={theme}>
            <Div>
              <div>
            <Profile />
            
            
                {/* <Budgets /> */}
                {/* <Anniversaries /> */}
                {/* <ClientSurveyStats /> */}
              </div>

              <div className="mt-5">
                <Row className='mt-5'>
                    <Col className='text-center'>
                        <StyledButton className='big-btn px-5 py-5' href="/specialprojects">
                            <i className="fa-duotone fa-tags fa-10x fa-fw"></i>
                            <p className='lead mt-3'>Special Projects</p>
                        </StyledButton>
                    </Col>
                    <Col className='text-center'>
                        <StyledButton className='big-btn px-5 py-5' href="/clientsurveystats">
                            <i className="fa-duotone fa-comments-question-check fa-10x fa-fw"></i>
                            <p className='lead mt-3'>Client Satisfaction</p>
                        </StyledButton>
                    </Col>
                    <Col className='text-center'>
                          <StyledButton className='big-btn px-5 py-5' href="/candsurveystats">
                              <i className="fa-duotone fa-poll-people fa-10x fa-fw"></i>
                              <p className='lead mt-3'>Candidate Satisfaction</p>
                          </StyledButton>
                      </Col>
                  </Row>
              </div>
              
            </Div>
          </ThemeProvider>
        );
  } else {
    return (
      <p>You must login!</p>
    )
  }    
}

export default Dashboard;