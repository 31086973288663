import React from 'react';
import StatsFullCurrentYear from './StatsFullCurrentYear';
import StatsFullLastYear from './StatsFullLastYear';
import StatsSummaryCurrentYear from './StatsSummaryCurrentYear';
import StatsSummaryLastYear from './StatsSummaryLastYear';
import { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { useAuth0 } from '@auth0/auth0-react';

const ClientSurveyStats = () => {

    const { user } = useAuth0();
    

    if (user.name === "anorton@pprhr.com" || user.name === "gnorton@pprhr.com" || user.name === "kbenson@pprhr.com" || user.name === "jkelly@pprhr.com") {
        return (
            <ThemeProvider theme={theme}>
                <div>
                    <StatsFullCurrentYear />
                    <StatsFullLastYear />
                </div>
            </ThemeProvider>
            )
    } else {
        return (
            <ThemeProvider theme={theme}>
                <div>
                    <StatsSummaryCurrentYear />
                    <StatsSummaryLastYear />
                </div>
            </ThemeProvider>
        )
    }

}

export default ClientSurveyStats;