import React, { Component } from 'react';
import { Jumbotron, Row, Col, Table, Button } from 'react-bootstrap';
import { config } from '../../Config/Constants';
import styled from 'styled-components';

const API_URL = config.url.API_URL;

// const year = new Date().getFullYear();

const Div = styled.div `

    .score-cards-big{
        border: 1px solid black;
        border-radius: 0.5rem;
        padding: 1rem;
        margin: 1rem;
    }

    .score-cards-sml{
        border: 1px solid black;
        border-radius: 0.25rem;
        padding: .5rem;
        margin: 1rem;
    }
`

const StyledButton = styled(Button) `
    margin: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: rgba(255,255,255, 0.7);
    border: 1px solid ${props => props.theme.colors.medium_blue};
    &:hover{
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.medium_blue};
        border: 1px solid ${props => props.theme.colors.orange};
    }

    div{
        margin-top: 1em;
        font-size: .75em;
    }
    

`

class StatsFullCurrentYear extends Component {
    constructor(props) {
        super(props);

        this.state = {
            surveyData: [],
            surveyYear: 0,
            surveyScoreConsulting: 0,
            consultingScoreDiv: 0,
            surveyScoreTalent: 0,
            talentScoreDiv: 0,

            
            candace: false,
            candaceScore: 0,
            candaceScoreDiv: 0,
            dorothy: false,
            dorothyScore: 0,
            dorothyScoreDiv: 0,
            denise: false,
            deniseScore: 0,
            deniseScoreDiv: 0,
            haley: false,
            haleyScore: 0,
            haleyScoreDiv: 0,
            gabriela: false,
            gabrielaScore: 0,
            gabrielaDiv: 0,
            hayley: false,
            hayleyScore: 0,
            hayleyScoreDiv: 0,
            jazmin: false,
            jazminScore: 0,
            jazminScoreDiv: 0,
            kacye: false,
            kacyeScore: 0,
            kacyeScoreDiv: 0,
            kathy: false,
            kathyScore: 0,
            kathyScoreDiv: 0,
            mercedes: false,
            mercedesScore: 0,
            mercedesScoreDiv: 0,
            lauren: false,
            laurenScore: 0,
            laurenScoreDiv: 0,
            luke: false,
            lukeScore: 0,
            lukeScoreDiv: 0,
            tracy: false,
            tracyScore: 0,
            tracyScoreDiv: 0,

            //Consulting
            talentedConScore: 0,
            talentedConDiv: 0,
            conUnderstandingScore: 0,
            conUnderstandingDiv: 0,
            timelyDelScore: 0,
            timelyDelDiv: 0,
            solutionScore: 0,
            solutionScoreDiv: 0,
            fairPriceScore: 0,
            fairPriceDiv: 0,
            stakeholderScore: 0,
            stakeholderDiv: 0,
            timelyCommScore: 0,
            timelyCommDiv: 0,
            customScore: 0,
            customDiv: 0,
            ownershipScore: 0,
            ownershipDiv: 0,
            learnScore: 0,
            learnDiv: 0,
            overallValScore: 0,
            overallValDiv: 0,

            //Talent
            talentedCandScore: 0,
            talentedCandDiv: 0,
            talEfficiencyScore: 0,
            talEfficiencyDiv: 0,
            talStakeholdersScore: 0,
            talStakeholdersDiv: 0,
            talCommsScore: 0,
            talCommsDiv: 0,
            talMethodScore: 0,
            talMethodDiv: 0,
            talPrioritiesScore: 0,
            talPrioritiesDiv: 0,
            talExpectScore: 0,
            talExpectDiv: 0,

            //Refer
            consultingTotal: 0,
            consultingYes: 0,
            consultingNo: 0,
            consultingMaybe: 0,
            talentTotal: 0,
            talentYes: 0,
            talentNo: 0,
            talentMaybe: 0


        }
    }

    componentDidMount() {
        console.log("grabbing survey data");
        this.grabSurveyData();
    }

    grabSurveyData() {
        fetch(API_URL + "/client/surveydata/currentyear")
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((result) => {
                let consultingScore = 0;
                let consultingScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    let tempConScore = +result[i].formTalentedConsultants + +result[i].formConsultantUnderstands + +result[i].formTimely + +result[i].formSolutionAttitude + +result[i].formFairPrice + +result[i].formStakeholders + +result[i].formCommunications + +result[i].formProjects + +result[i].formOwnership + +result[i].formLearn + +result[i].formExpectations;
                    consultingScore = +consultingScore + +tempConScore;
                    this.setState({ surveyScoreConsulting: consultingScore });
                    if (result[i].formTalentedConsultants !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formConsultantUnderstands !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formTimely !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formSolutionAttitude !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formFairPrice !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formStakeholders !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formCommunications !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formProjects !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formOwnership !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formLearn !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formExpectations !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    this.setState({ consultingScoreDiv: consultingScoreDiv });
                }
                let talentScore = 0;
                let talentScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    let tempTalentScore = +result[i].formCandidates + +result[i].formEfficiency + +result[i].formTalentStakeholders + +result[i].formTalentCommunications + +result[i].formTalentMethod + +result[i].formTalentPriorities + +result[i].formTalentExpectations;
                    talentScore = +talentScore + +tempTalentScore;
                    this.setState({ surveyScoreTalent: talentScore });
                    if (result[i].formCandidates !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formEfficiency !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentStakeholders!== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentCommunications !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentMethod !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentPriorities !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentExpectations !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    this.setState({ talentScoreDiv: talentScoreDiv });
                }

                
                let candaceScore = 0;
                let candaceScoreDiv = 0;
                let dorothyScore = 0;
                let dorothyScoreDiv = 0;
                let deniseScore = 0;
                let deniseScoreDiv = 0;
                let haleyScore = 0;
                let haleyScoreDiv = 0;
                let gabrielaScore = 0;
                let gabrielaScoreDiv = 0;
                let hayleyScore = 0;
                let hayleyScoreDiv = 0;
                let jazminScore = 0;
                let jazminScoreDiv = 0;
                let kacyeScore = 0;
                let kacyeScoreDiv = 0;
                let kathyScore = 0;
                let kathyScoreDiv = 0;
                let laurenScore = 0;
                let laurenScoreDiv = 0;
                let lukeScore = 0;
                let lukeScoreDiv = 0;
                let mercedesScore = 0;
                let mercedesScoreDiv = 0;
                let tracyScore = 0;
                let tracyScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    
                    candaceScore = candaceScore + +result[i].formCandaceA_Rating;
                    dorothyScore = dorothyScore + +result[i].formDorothyB_Rating;
                    deniseScore = deniseScore + +result[i].formDeniseMF_Rating;
                    haleyScore = haleyScore + +result[i].formHaleyM_Rating;
                    gabrielaScore = gabrielaScore + +result[i].formGabrielaN_Rating;
                    hayleyScore = hayleyScore + +result[i].formHayleyT_Rating;
                    jazminScore = jazminScore + +result[i].formJazminK_Rating;
                    kacyeScore = kacyeScore + +result[i].formKacyeB_Rating;
                    kathyScore = kathyScore + +result[i].formKathyB_Rating;
                    mercedesScore = mercedesScore + +result[i].formMercedesC_Rating;
                    laurenScore = laurenScore + +result[i].formLaurenL_Rating;
                    lukeScore = lukeScore + +result[i].formLukeB_Rating;
                    tracyScore = tracyScore + +result[i].formTracyM_Rating;
                    
                    if (result[i].formCandaceA_Rating !== "") {
                        candaceScoreDiv = candaceScoreDiv + 1;
                    }
                    if (result[i].formDorothyB_Rating !== "") {
                        dorothyScoreDiv = dorothyScoreDiv + 1;
                    }
                    if (result[i].formDeniseMF_Rating !== "") {
                        deniseScoreDiv = deniseScoreDiv + 1;
                    }
                    if (result[i].formHaleyM_Rating !== "") {
                        haleyScoreDiv = haleyScoreDiv + 1;
                    }
                    if (result[i].formGabrielaN_Rating !== "") {
                        gabrielaScoreDiv = gabrielaScoreDiv + 1;
                    }
                    if (result[i].formHayleyT_Rating !== "") {
                        hayleyScoreDiv = hayleyScoreDiv + 1;
                    }
                    if (result[i].formJazminK_Rating !== "") {
                        jazminScoreDiv = jazminScoreDiv + 1;
                    }
                    if (result[i].formKacyeB_Rating !== "") {
                        kacyeScoreDiv = kacyeScoreDiv + 1;
                    }
                    if (result[i].formKathyB_Rating !== "") {
                        kathyScoreDiv = kathyScoreDiv + 1;
                    }
                    
                    if (result[i].formLaurenL_Rating !== "") {
                        laurenScoreDiv = laurenScoreDiv + 1;
                    }
                    if (result[i].formLukeB_Rating !== "") {
                        lukeScoreDiv = lukeScoreDiv + 1;
                    }
                    if (result[i].formMercedesC_Rating !== "") {
                        mercedesScoreDiv = mercedesScoreDiv + 1;
                    }
                    
                    if (result[i].formTracyM_Rating !== "") {
                        tracyScoreDiv = tracyScoreDiv + 1;
                    }
                    this.setState({
                        
                        candaceScore: candaceScore,
                        candaceScoreDiv: candaceScoreDiv,
                        dorothyScore: dorothyScore,
                        dorothyScoreDiv: dorothyScoreDiv,
                        deniseScore: deniseScore,
                        deniseScoreDiv: deniseScoreDiv,
                        haleyScore: haleyScore,
                        haleyScoreDiv: haleyScoreDiv,
                        gabrielaScore: gabrielaScore,
                        gabrielaScoreDiv: gabrielaScoreDiv,
                        hayleyScore: hayleyScore,
                        hayleyScoreDiv: hayleyScoreDiv,
                        jazminScore: jazminScore,
                        jazminScoreDiv: jazminScoreDiv,
                        kacyeScore: kacyeScore,
                        kacyeScoreDiv: kacyeScoreDiv,
                        kathyScore: kathyScore,
                        kathyScoreDiv: kathyScoreDiv,
                        laurenScore: laurenScore,
                        laurenScoreDiv: laurenScoreDiv,
                        lukeScore: lukeScore,
                        lukeScoreDiv: lukeScoreDiv,
                        mercedesScore: mercedesScore,
                        mercedesScoreDiv: mercedesScoreDiv,
                        tracyScore: tracyScore,
                        tracyScoreDiv: tracyScoreDiv,
                    });
                }

                let talentedConScore = 0;
                let talentedConDiv = 0;
                let conUnderstandingScore = 0;
                let conUnderstandingDiv = 0;
                let timelyDelScore = 0;
                let timelyDelDiv = 0;
                let solutionScore = 0;
                let solutionScoreDiv = 0;
                let fairPriceScore = 0;
                let fairPriceDiv = 0;
                let stakeholderScore = 0;
                let stakeholderDiv = 0;
                let timelyCommScore = 0;
                let timelyCommDiv = 0;
                let customScore = 0;
                let customDiv = 0;
                let ownershipScore = 0;
                let ownershipDiv = 0;
                let learnScore = 0;
                let learnDiv = 0;
                let overallValScore = 0;
                let overallValDiv = 0;
                let consultingTotal = 0;
                let consultingYes = 0;
                let consultingNo = 0;
                let consultingMaybe = 0;

                let talentedCandScore = 0;
                let talentedCandDiv = 0;
                let talEfficiencyScore = 0;
                let talEfficiencyDiv = 0;
                let talStakeholdersScore = 0;
                let talStakeholdersDiv = 0;
                let talCommsScore = 0;
                let talCommsDiv = 0;
                let talMethodScore = 0;
                let talMethodDiv = 0;
                let talPrioritiesScore = 0;
                let talPrioritiesDiv = 0;
                let talExpectScore = 0;
                let talExpectDiv = 0;
                
                let talentTotal = 0;
                let talentYes = 0;
                let talentNo = 0;
                let talentMaybe = 0;
                for (let i = 0; i < result.length; i++){
                    talentedConScore = talentedConScore + +result[i].formTalentedConsultants;
                    conUnderstandingScore = conUnderstandingScore + +result[i].formConsultantUnderstands;
                    timelyDelScore = timelyDelScore + +result[i].formTimely;
                    solutionScore = solutionScore + +result[i].formSolutionAttitude;
                    fairPriceScore = fairPriceScore + +result[i].formFairPrice;
                    stakeholderScore = stakeholderScore + +result[i].formStakeholders;
                    timelyCommScore = timelyCommScore + +result[i].formCommunications;
                    customScore = customScore + +result[i].formProjects;
                    ownershipScore = ownershipScore + +result[i].formOwnership;
                    learnScore = learnScore + +result[i].formLearn;
                    overallValScore = overallValScore + +result[i].formExpectations;
                    if (result[i].formConsultingRefer === "yes") {
                        consultingYes++;
                        consultingTotal++;
                    } else if (result[i].formConsultingRefer === "no") {
                        consultingNo++;
                        consultingTotal++;
                    } else if (result[i].formConsultingRefer === "other") {
                        consultingMaybe++;
                        consultingTotal++;
                    }
                    if (result[i].formTalentedConsultants !== "") {
                        talentedConDiv = talentedConDiv + 1;
                    }
                    if (result[i].formConsultantUnderstands !== "") {
                        conUnderstandingDiv = conUnderstandingDiv + 1;
                    }
                    if (result[i].formTimely !== "") {
                        timelyDelDiv = timelyDelDiv + 1;
                    }
                    if (result[i].formSolutionAttitude !== "") {
                        solutionScoreDiv = solutionScoreDiv + 1;
                    }
                    if (result[i].formFairPrice !== "") {
                        fairPriceDiv = fairPriceDiv + 1;
                    }
                    if (result[i].formStakeholders !== "") {
                        stakeholderDiv = stakeholderDiv + 1;
                    }
                    if (result[i].formCommunications !== "") {
                        timelyCommDiv = timelyCommDiv + 1;
                    }
                    if (result[i].formProjects !== "") {
                        customDiv = customDiv + 1;
                    }
                    if (result[i].formOwnership !== "") {
                        ownershipDiv = ownershipDiv + 1;
                    }
                    if (result[i].formLearn !== "") {
                        learnDiv = learnDiv + 1;
                    }
                    if (result[i].formExpectations !== "") {
                        overallValDiv = overallValDiv + 1;
                    }
                    talentedCandScore = talentedCandScore + +result[i].formCandidates;
                    talEfficiencyScore = talEfficiencyScore + +result[i].formEfficiency;
                    talStakeholdersScore = talStakeholdersScore + +result[i].formTalentStakeholders;
                    talCommsScore = talCommsScore + +result[i].formTalentCommunications;
                    talMethodScore = talMethodScore + +result[i].formTalentMethod;
                    talPrioritiesScore = talPrioritiesScore + +result[i].formTalentPriorities;
                    talExpectScore = talExpectScore + +result[i].formTalentExpectations;
                    if (result[i].formTalentRefer === "yes") {
                        talentYes++;
                        talentTotal++;
                    } else if (result[i].formTalentRefer === "no") {
                        talentNo++;
                        talentTotal++;
                    } else if (result[i].formTalentRefer === "other") {
                        talentMaybe++;
                        talentTotal++;
                    }
                    if (result[i].formCandidates !== "") {
                        talentedCandDiv = talentedCandDiv + 1;
                    }
                    if (result[i].formEfficiency !== "") {
                        talEfficiencyDiv = talEfficiencyDiv + 1;
                    }
                    if (result[i].formTalentStakeholders !== "") {
                        talStakeholdersDiv = talStakeholdersDiv + 1;
                    }
                    if (result[i].formTalentCommunications !== "") {
                        talCommsDiv = talCommsDiv + 1;
                    }
                    if (result[i].formTalentMethod !== "") {
                        talMethodDiv = talMethodDiv + 1;
                    }
                    if (result[i].formTalentPriorities !== "") {
                        talPrioritiesDiv = talPrioritiesDiv + 1;
                    }
                    if (result[i].formTalentExpectations !== "") {
                        talExpectDiv = talExpectDiv + 1;
                    }
                    
                    this.setState({
                        talentedConScore: talentedConScore,
                        talentedConDiv: talentedConDiv,
                        conUnderstandingScore: conUnderstandingScore,
                        conUnderstandingDiv: conUnderstandingDiv,
                        timelyDelScore: timelyDelScore,
                        timelyDelDiv: timelyDelDiv,
                        solutionScore: solutionScore,
                        solutionScoreDiv: solutionScoreDiv,
                        fairPriceScore: fairPriceScore,
                        fairPriceDiv: fairPriceDiv,
                        stakeholderScore: stakeholderScore,
                        stakeholderDiv: stakeholderDiv,
                        timelyCommScore: timelyCommScore,
                        timelyCommDiv: timelyCommDiv,
                        customScore: customScore,
                        customDiv: customDiv,
                        ownershipScore: ownershipScore,
                        ownershipDiv: ownershipDiv,
                        learnScore: learnScore,
                        learnDiv: learnDiv,
                        overallValScore: overallValScore,
                        overallValDiv: overallValDiv,
                        consultingTotal: consultingTotal,
                        consultingYes: consultingYes,
                        consultingNo: consultingNo,
                        consultingMaybe: consultingMaybe,

                        talentedCandScore: talentedCandScore,
                        talentedCandDiv: talentedCandDiv,
                        talEfficiencyScore: talEfficiencyScore,
                        talEfficiencyDiv: talEfficiencyDiv,
                        talStakeholdersScore: talStakeholdersScore,
                        talStakeholdersDiv: talStakeholdersDiv,
                        talCommsScore: talCommsScore,
                        talCommsDiv: talCommsDiv,
                        talMethodScore: talMethodScore,
                        talMethodDiv: talMethodDiv,
                        talPrioritiesScore: talPrioritiesScore,
                        talPrioritiesDiv: talPrioritiesDiv,
                        talExpectScore: talExpectScore,
                        talExpectDiv: talExpectDiv,
                        talentTotal: talentTotal,
                        talentYes: talentYes,
                        talentNo: talentNo,
                        talentMaybe: talentMaybe
                    })
                }

                this.setState({
                    surveyData: result,
                    surveyScoreTalent: talentScore
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }


    render() {
        
        return (
            <Jumbotron className="text-dark">
                <Div>
                    <Row>
                        <Col>
                            <h3 className='display-4'>Client Satisfaction Survey 2024</h3>
                            <StyledButton href="/clientsurveyresults">Detailed Report</StyledButton>
                            <Row>
                                <Col className="text-center score-cards-big">
                                    <h4>Overall Consulting Score:</h4>
                                    <h1 className="display-1">{(this.state.surveyScoreConsulting/this.state.consultingScoreDiv).toFixed(2)}</h1>
                                </Col>
                                <Col className="text-center score-cards-big">
                                    <h4>Overall Talent Score:</h4>
                                    <h1 className="display-1">{(this.state.surveyScoreTalent/this.state.talentScoreDiv).toFixed(2)}</h1>
                                </Col>
                            </Row>
                            <hr />
                            <h4>Team Members - Client Ratings</h4>
                            <Row>
                                
                                
                                <Col className="text-center score-cards-sml">
                                    <div>Candace</div>
                                    {
                                        this.state.candaceScore > 0 ? <h4>{(this.state.candaceScore/this.state.candaceScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Denise</div>
                                    {
                                        this.state.deniseScore > 0 ? <h4>{(this.state.deniseScore/this.state.deniseScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Dorothy</div>
                                    {
                                        this.state.dorothyScore > 0 ? <h4>{(this.state.dorothyScore/this.state.dorothyScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Gabriela</div>
                                    {
                                        this.state.gabrielaScore > 0 ? <h4>{(this.state.gabrielaScore/this.state.gabrielaScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Haley(Mac)</div>
                                    {
                                        this.state.haleyScore > 0 ? <h4>{(this.state.haleyScore/this.state.haleyScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Hayley</div>
                                    {
                                        this.state.hayleyScore > 0 ? <h4>{(this.state.hayleyScore/this.state.hayleyScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Jazmin</div>
                                    {
                                        this.state.jazminScore > 0 ? <h4>{(this.state.jazminScore/this.state.jazminScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Kacye</div>
                                    {
                                        this.state.kacyeScore > 0 ? <h4>{(this.state.kacyeScore/this.state.kacyeScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Kathy</div>
                                    {
                                        this.state.kathyScore > 0 ? <h4>{(this.state.kathyScore/this.state.kathyScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col className="text-center score-cards-sml">
                                    <div>Lauren</div>
                                    {
                                        this.state.laurenScore > 0 ? <h4>{(this.state.laurenScore/this.state.laurenScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Luke</div>
                                    {
                                        this.state.lukeScore > 0 ? <h4>{(this.state.lukeScore/this.state.lukeScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Mercedes</div>
                                    {
                                        this.state.mercedesScore > 0 ? <h4>{(this.state.mercedesScore/this.state.mercedesScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                
                                <Col className="text-center score-cards-sml">
                                    <div>Tracy</div>
                                    {
                                        this.state.tracyScore > 0 ? <h4>{(this.state.tracyScore/this.state.tracyScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>
                            <hr />
                            <h4>Consulting Categories - Client Ratings</h4>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Talented</div>
                                    <div>Consultants</div>
                                    {
                                        this.state.talentedConScore > 0 ? <h4>{(this.state.talentedConScore/this.state.talentedConDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Consultant</div>
                                    <div>Understanding</div>
                                    {
                                        this.state.conUnderstandingScore > 0 ? <h4>{(this.state.conUnderstandingScore/this.state.conUnderstandingDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Timely</div>
                                    <div>Delivery</div>
                                    {
                                        this.state.timelyDelScore > 0 ? <h4>{(this.state.timelyDelScore/this.state.timelyDelDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Solution</div>
                                    <div>Oriented</div>
                                    {
                                        this.state.solutionScore > 0 ? <h4>{(this.state.solutionScore/this.state.solutionScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Fair</div>
                                    <div>Price</div>
                                    {
                                        this.state.fairPriceScore > 0 ? <h4>{(this.state.fairPriceScore/this.state.fairPriceDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Stakeholder</div>
                                    <div>Cooperation</div>
                                    {
                                        this.state.stakeholderScore > 0 ? <h4>{(this.state.stakeholderScore/this.state.stakeholderDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Timely</div>
                                    <div>Communication</div>
                                    {
                                        this.state.timelyCommScore > 0 ? <h4>{(this.state.timelyCommScore/this.state.timelyCommDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Project</div>
                                    <div>Customization</div>
                                    {
                                        this.state.customScore > 0 ? <h4>{(this.state.customScore/this.state.customDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Ownership</div>
                                    <div>Opportunities</div>
                                    {
                                        this.state.ownershipScore > 0 ? <h4>{(this.state.ownershipScore/this.state.ownershipDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Learns</div>
                                    <div>Culture</div>
                                    {
                                        this.state.learnScore > 0 ? <h4>{(this.state.learnScore/this.state.learnDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Overall</div>
                                    <div>Value</div>
                                    {
                                        this.state.overallValScore > 0 ? <h4>{(this.state.overallValScore/this.state.overallValDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>
                            
                            
                            <hr />
                            <h4>Talent Acquisition Categories - Client Ratings</h4>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Talented</div>
                                    <div>Candidates</div>
                                    {
                                        this.state.talentedCandScore > 0 ? <h4>{(this.state.talentedCandScore/this.state.talentedCandDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Quality &</div>
                                    <div>Efficiency</div>
                                    {
                                        this.state.talEfficiencyScore > 0 ? <h4>{(this.state.talEfficiencyScore/this.state.talEfficiencyDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Stakeholder</div>
                                    <div>Cooperation</div>
                                    {
                                        this.state.talStakeholdersScore > 0 ? <h4>{(this.state.talStakeholdersScore/this.state.talStakeholdersDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Timely</div>
                                    <div>Communication</div>
                                    {
                                        this.state.talCommsScore > 0 ? <h4>{(this.state.talCommsScore/this.state.talCommsDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Unique</div>
                                    <div>Methodologies</div>
                                    {
                                        this.state.talMethodScore > 0 ? <h4>{(this.state.talMethodScore/this.state.talMethodDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Learn</div>
                                    <div>Culture</div>
                                    {
                                        this.state.talPrioritiesScore > 0 ? <h4>{(this.state.talPrioritiesScore/this.state.talPrioritiesDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Overall</div>
                                    <div>Value</div>
                                    {
                                        this.state.talExpectScore > 0 ? <h4>{(this.state.talExpectScore/this.state.talExpectDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>

                            <hr />
                            <Row>
                                <Col className="text-center">
                                    <h4>Consulting - Would you refer or engage PPR for future projects?</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Yes</div>
                                    <h4>{this.state.consultingYes} / {this.state.consultingTotal}</h4>
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>No</div>
                                    <h4>{this.state.consultingNo} / {this.state.consultingTotal}</h4>
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Maybe</div>
                                    <h4>{this.state.consultingMaybe} / {this.state.consultingTotal}</h4>
                                </Col>
                            </Row>

                            <hr />
                            <Row>
                                <Col className="text-center">
                                    <h4>Talent Acquisition - Would you refer or engage PPR for future projects?</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Yes</div>
                                    <h4>{this.state.talentYes} / {this.state.talentTotal}</h4>
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>No</div>
                                    <h4>{this.state.talentNo} / {this.state.talentTotal}</h4>
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Maybe</div>
                                    <h4>{this.state.talentMaybe} / {this.state.talentTotal}</h4>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Name</th>
                                        <th>Score: Consulting</th>
                                        <th>Score: Talent Attraction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.surveyData.map((client) => {
                                            let consultingScoreDiv = 0;
                                            let talentScoreDiv = 0;

                                            const consultingScore = +client.formTalentedConsultants + +client.formConsultantUnderstands + +client.formTimely + +client.formSolutionAttitude + +client.formFairPrice + +client.formStakeholders + +client.formCommunications + +client.formProjects + +client.formOwnership + +client.formLearn + +client.formExpectations;

                                            if (client.formTalentedConsultants !== ""){ consultingScoreDiv++ };
                                            if (client.formConsultantUnderstands !== ""){ consultingScoreDiv++ };
                                            if (client.formTimely !== ""){ consultingScoreDiv++ };
                                            if (client.formSolutionAttitude !== ""){ consultingScoreDiv++ };
                                            if (client.formFairPrice !== ""){ consultingScoreDiv++ };
                                            if (client.formStakeholders !== ""){ consultingScoreDiv++ };
                                            if (client.formCommunications !== ""){ consultingScoreDiv++ };
                                            if (client.formProjects !== ""){ consultingScoreDiv++ };
                                            if (client.formOwnership !== ""){ consultingScoreDiv++ };
                                            if (client.formLearn !== ""){ consultingScoreDiv++ };
                                            if (client.formExpectations !== "") { consultingScoreDiv++ };
                                            
                                            const talentScore = +client.formCandidates + +client.formEfficiency + +client.formTalentStakeholders + +client.formTalentCommunications + +client.formTalentMethod + +client.formTalentPriorities + +client.formTalentExpectations;

                                            if (client.formCandidates !== ""){ talentScoreDiv++ };
                                            if (client.formEfficiency !== ""){ talentScoreDiv++ };
                                            if (client.formTalentStakeholders !== ""){ talentScoreDiv++ };
                                            if (client.formTalentCommunications !== ""){ talentScoreDiv++ };
                                            if (client.formTalentMethod !== ""){ talentScoreDiv++ };
                                            if (client.formTalentPriorities !== ""){ talentScoreDiv++ };
                                            if (client.formTalentExpectations !== ""){ talentScoreDiv++ };
                                            
                                            return (
                                                <tr key = { client.id } >
                                                    <td>{client.orgName}</td>
                                                    <td>{client.name}</td>
                                                    <td>{
                                                        consultingScore > 0 ? (consultingScore / consultingScoreDiv).toFixed(2) : "--"
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            talentScore > 0 ? (talentScore / talentScoreDiv).toFixed(2) : "--"
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }                                        
                                    )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Div>
            </Jumbotron>
        )
    }
}

export default StatsFullCurrentYear;