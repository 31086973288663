import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;

    .help-text{
        color: red;
        font-size: .75rem;
        padding-left: 1rem;
        font-style: italic;
    }

    .help-text-services{
        color: red;
        font-size: .75rem;
        padding-left: .2rem;
        font-style: italic;
    }
`



class IntroQuestions extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <Form.Group className="mb-0" as={Col} controlId="name">
                                        <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        required
                                        name="name"
                                        onChange={this.props.handleChange}
                                        />
                                </Form.Group>
                                {
                                    this.props.name === "" ?
                                        <p className="help-text">*Name field is required</p> : <p className="help-text"></p>
                                }
                                
                                
                                    <Form.Group className="mb-0" as={Col} controlId="title">
                                        <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        required
                                        name="title"
                                        onChange={this.props.handleChange}
                                        />
                                </Form.Group>
                                {
                                    this.props.title === "" ?
                                        <p className="help-text">*Title field is required</p> : <p className="help-text"></p>
                                }
                                
                                <Form.Group className="mb-0" as={Col} controlId="orgName">
                                    <Form.Label>Organization Name</Form.Label>
                                    <Form.Control
                                        required
                                    name="orgName"
                                    onChange={this.props.handleChange}
                                    />
                                </Form.Group>
                                {
                                    this.props.orgName === "" ?
                                        <p className="help-text">*Organization Name field is required</p> : <p className="help-text"></p>
                                }
                              

                                <Form.Group
                                    name="formConsultants"
                                    as={Row}
                                    controlId="formConsultants"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label className='font-weight-bold'   column sm={5}>
                                        Please select the names of the consultant(s) from PPR who have supported your project(s).
                                    </Form.Label>
                                    <Col>
                                        

                                        <Form.Check
                                        type="checkbox"
                                        label="Candace Anderson"
                                        name="formCandace"
                                        id="formCandace"
                                        defaultChecked={this.props.formCandace}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Kathy Bennett"
                                        name="formKathy"
                                        id="formKathy"
                                        defaultChecked={
                                            this.props.formKathy
                                        }
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Kacye Benson"
                                        name="formKacye"
                                        id="formKacye"
                                        defaultChecked={
                                            this.props.formKacye
                                        }
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Luke Benson"
                                        name="formLuke"
                                        id="formLuke"
                                        defaultChecked={this.props.formLuke}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Dorothy Buffington"
                                        name="formDorothy"
                                        id="formDorothy"
                                        defaultChecked={this.props.formDorothy}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Mercedes Contreras"
                                        name="formMercedes"
                                        id="formMercedes"
                                        defaultChecked={this.props.formMercedes}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Jazmin Kelly"
                                        name="formJazmin"
                                        id="formJazmin"
                                        defaultChecked={this.props.formJazmin}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Lauren Lloyd"
                                        name="formLauren"
                                        id="formLauren"
                                        defaultChecked={this.props.formLauren}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Denise Maloney-Flores"
                                        name="formDenise"
                                        id="formDenise"
                                        defaultChecked={this.props.formDenise}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Haley McCartney"
                                        name="formHaley"
                                        id="formHaley"
                                        defaultChecked={this.props.formHaley}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Tracy Miller"
                                        name="formTracy"
                                        id="formTracy"
                                        defaultChecked={this.props.formTracy}
                                        />


                                        <Form.Check
                                        type="checkbox"
                                        label="Gabriela Norton"
                                        name="formGabriela"
                                        id="formGabriela"
                                        defaultChecked={
                                            this.props.formGabriela
                                        }
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Hayley Tibbetts"
                                        name="formHayley"
                                        id="formHayley"
                                        defaultChecked={
                                            this.props.formHayley
                                        }
                                        />

                                        
                                    </Col>
                                </Form.Group>
                                <hr/>
                                <Form.Group className="mb-0"
                                    name="formWhichServices"
                                    as={Row}
                                controlId="formWhichServices"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label className='font-weight-bold'  column sm={5}>
                                        What services did you receive?
                                    </Form.Label>
                                    
                                    <Col>
                                    <Row>
                                            <Form.Check
                                        inline
                                        type="radio"
                                        label="Human Capital Services"
                                        value="consulting"
                                        name="formWhichServices"
                                        id="formWhichServices-consulting"
                                        defaultChecked={
                                        this.props.formWhichServices === "consulting"
                                        }
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Executive Search & Talent Attraction"
                                        value="talent"
                                        name="formWhichServices"
                                        id="formWhichServices-talent"
                                        defaultChecked={
                                        this.props.formWhichServices === "talent"
                                        }                               
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="All of the above"
                                        value="all"
                                        name="formWhichServices"
                                        id="formWhichServices-all"
                                        defaultChecked={
                                        this.props.formWhichServices === "all"
                                        }
                                                
                                            />
                                        
                                        </Row>
                                    {
                                    this.props.formWhichServices === "" ?
                                        <p className="help-text-services">*Services field is required</p> : <p className="help-text"></p>
                                            }</Col>
                                </Form.Group>
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
           </ThemeProvider>
        )
    }
}

export default IntroQuestions;