import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';


const Div = styled.div`
    background-color: white;
    color: black;
`

const Img = styled.img`
    width: 10rem;
    border-radius: 10rem;
    margin-top: 1.5rem;
`

class Team extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Your Human Capital Team</h4>
                                
                    {(this.props.formCandace === true ? <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Candace</h3>
                            <h3>Anderson</h3>
                            <Img src='https://pprhr.com/assets/images/team/candace.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formCandaceA_Rating"
                                controlId="formCandaceA_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Candace is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formCandaceA_Rating"
                                    id="formCandaceA_Rating-1"
                                    defaultChecked={this.props.formCandaceA_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formCandaceA_Rating"
                                    id="formCandaceA_Rating-2"
                                    defaultChecked={this.props.formCandaceA_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formCandaceA_Rating"
                                    id="formCandaceA_Rating-3"
                                    defaultChecked={this.props.formCandaceA_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formCandaceA_Rating"
                                    id="formCandaceA_Rating-4"
                                    defaultChecked={this.props.formCandaceA_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formCandaceA_Rating"
                                    id="formCandaceA_Rating-5"
                                    defaultChecked={this.props.formCandaceA_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formCandaceA_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formCandaceA_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formCandaceA_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                                </div> : null)}
                                

                                {(this.props.formDenise === true ? <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Denise</h3>
                            <h3>Maloney-Flores</h3>
                            <Img src='https://pprhr.com/assets/images/team/denise.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formDeniseMF_Rating"
                                controlId="formDeniseMF_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Denise is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-1"
                                    defaultChecked={this.props.formDeniseMF_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-2"
                                    defaultChecked={this.props.formDeniseMF_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-3"
                                    defaultChecked={this.props.formDeniseMF_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-4"
                                    defaultChecked={this.props.formDeniseMF_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-5"
                                    defaultChecked={this.props.formDeniseMF_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formDeniseMF_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formDeniseMF_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formDeniseMF_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                                </div> : null)}
                                
                                {(this.props.formDorothy === true ? 
                                    <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Dorothy</h3>
                            <h3>Buffington</h3>
                            <Img src='https://pprhr.com/assets/images/team/placeholder-female.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formDorothyB_Rating"
                                controlId="formDorothyB_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Dorothy is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formDorothyB_Rating"
                                    id="formDorothyB_Rating-1"
                                    defaultChecked={this.props.formDorothyB_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formDorothyB_Rating"
                                    id="formDorothyB_Rating-2"
                                    defaultChecked={this.props.formDorothyB_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formDorothyB_Rating"
                                    id="formDorothyB_Rating-3"
                                    defaultChecked={this.props.formDorothyB_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formDorothyB_Rating"
                                    id="formDorothyB_Rating-4"
                                    defaultChecked={this.props.formDorothyB_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formDorothyB_Rating"
                                    id="formDorothyB_Rating-5"
                                    defaultChecked={this.props.formDorothyB_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formDorothyB_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formDorothyB_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formDorothyB_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                    : null)}


                                {(this.props.formGabriela === true ? 
                                <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Gabriela</h3>
                            <h3>Norton</h3>
                            <Img src='https://pprhr.com/assets/images/team/gabriela.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formGabrielaN_Rating"
                                controlId="formGabrielaN_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Gabriela is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-1"
                                    defaultChecked={this.props.formGabrielaN_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-2"
                                    defaultChecked={this.props.formGabrielaN_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-3"
                                    defaultChecked={this.props.formGabrielaN_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-4"
                                    defaultChecked={this.props.formGabrielaN_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-5"
                                    defaultChecked={this.props.formGabrielaN_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formGabrielaN_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formGabrielaN_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formGabrielaN_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                    : null)}
                                
                                            {(this.props.formHaley === true ? 
                                    <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Haley</h3>
                            <h3>McCartney</h3>
                            <Img src='https://pprhr.com/assets/images/team/placeholder-female.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formHaleyM_Rating"
                                controlId="formHaleyM_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Haley is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formHaleyM_Rating"
                                    id="formHaleyM_Rating-1"
                                    defaultChecked={this.props.formHaleyM_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formHaleyM_Rating"
                                    id="formHaleyM_Rating-2"
                                    defaultChecked={this.props.formHaleyM_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formHaleyM_Rating"
                                    id="formHaleyM_Rating-3"
                                    defaultChecked={this.props.formHaleyM_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formHaleyM_Rating"
                                    id="formHaleyM_Rating-4"
                                    defaultChecked={this.props.formHaleyM_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formHaleyM_Rating"
                                    id="formHaleyM_Rating-5"
                                    defaultChecked={this.props.formHaleyM_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formHaleyM_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formHaleyM_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formHaleyM_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                    : null)}
                                

                                {(this.props.formHayley === true ? 
                                <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Hayley</h3>
                            <h3>Tibbetts</h3>
                            <Img src='https://pprhr.com/assets/images/team/hayley.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formHayleyT_Rating"
                                controlId="formHayleyT_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Hayley is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formHayleyT_Rating"
                                    id="formHayleyT_Rating-1"
                                    defaultChecked={this.props.formHayleyT_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formHayleyT_Rating"
                                    id="formHayleyT_Rating-2"
                                    defaultChecked={this.props.formHayleyT_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formHayleyT_Rating"
                                    id="formHayleyT_Rating-3"
                                    defaultChecked={this.props.formHayleyT_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formHayleyT_Rating"
                                    id="formHayleyT_Rating-4"
                                    defaultChecked={this.props.formHayleyT_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formHayleyT_Rating"
                                    id="formHayleyT_Rating-5"
                                    defaultChecked={this.props.formHayleyT_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formHayleyT_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formHayleyT_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formHayleyT_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                 : null)}
                                {(this.props.formJazmin === true ? 
                                <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Jazmin</h3>
                            <h3>Kelly</h3>
                            <Img src='https://pprhr.com/assets/images/team/jazmin.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formJazminK_Rating"
                                controlId="formJazminK_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Jazmin is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formJazminK_Rating"
                                    id="formJazminK_Rating-1"
                                    defaultChecked={this.props.formJazminK_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formJazminK_Rating"
                                    id="formJazminK_Rating-2"
                                    defaultChecked={this.props.formJazminK_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formJazminK_Rating"
                                    id="formJazminK_Rating-3"
                                    defaultChecked={this.props.formJazminK_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formJazminK_Rating"
                                    id="formJazminK_Rating-4"
                                    defaultChecked={this.props.formJazminK_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formJazminK_Rating"
                                    id="formJazminK_Rating-5"
                                    defaultChecked={this.props.formJazminK_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formJazminK_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formJazminK_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formJazminK_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                 : null)}
                                {(this.props.formKacye === true ? <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Kacye</h3>
                            <h3>Benson</h3>
                            <Img src='https://pprhr.com/assets/images/team/kacye.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formKacyeB_Rating"
                                controlId="formKacyeB_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Kacye is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formKacyeB_Rating"
                                    id="formKacyeB_Rating-1"
                                    defaultChecked={this.props.formKacyeB_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formKacyeB_Rating"
                                    id="formKacyeB_Rating-2"
                                    defaultChecked={this.props.formKacyeB_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formKacyeB_Rating"
                                    id="formKacyeB_Rating-3"
                                    defaultChecked={this.props.formKacyeB_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formKacyeB_Rating"
                                    id="formKacyeB_Rating-4"
                                    defaultChecked={this.props.formKacyeB_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formKacyeB_Rating"
                                    id="formKacyeB_Rating-5"
                                    defaultChecked={this.props.formKacyeB_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formKacyeB_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formKacyeB_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formKacyeB_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                                </div> : null)}
                                
                                {(this.props.formKathy === true ? <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Kathy</h3>
                            <h3>Bennett</h3>
                            <Img src='https://pprhr.com/assets/images/team/kathy.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formKathyB_Rating"
                                controlId="formKathyB_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Kathy is a highly talented search consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-1"
                                    defaultChecked={this.props.formKathyB_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-2"
                                    defaultChecked={this.props.formKathyB_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-3"
                                    defaultChecked={this.props.formKathyB_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-4"
                                    defaultChecked={this.props.formKathyB_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-5"
                                    defaultChecked={this.props.formKathyB_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formKathyB_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formKathyB_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formKathyB_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                                </div> : null)}
                                
                                
                                
                                {(this.props.formLauren === true ? 
                                    <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Lauren</h3>
                            <h3>Lloyd</h3>
                            <Img src='https://pprhr.com/assets/images/team/lauren.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formLaurenL_Rating"
                                controlId="formLaurenL_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Lauren is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-1"
                                    defaultChecked={this.props.formLaurenL_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-2"
                                    defaultChecked={this.props.formLaurenL_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-3"
                                    defaultChecked={this.props.formLaurenL_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-4"
                                    defaultChecked={this.props.formLaurenL_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-5"
                                    defaultChecked={this.props.formLaurenL_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formLaurenL_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formLaurenL_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formLaurenL_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                    : null)}
                                
                                {(this.props.formLuke === true ? 
                                    <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Luke</h3>
                            <h3>Benson</h3>
                            <Img src='https://pprhr.com/assets/images/team/luke.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formLukeB_Rating"
                                controlId="formLukeB_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Luke is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formLukeB_Rating"
                                    id="formLukeB_Rating-1"
                                    defaultChecked={this.props.formLukeB_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formLukeB_Rating"
                                    id="formLukeB_Rating-2"
                                    defaultChecked={this.props.formLukeB_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formLukeB_Rating"
                                    id="formLukeB_Rating-3"
                                    defaultChecked={this.props.formLukeB_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formLukeB_Rating"
                                    id="formLukeB_Rating-4"
                                    defaultChecked={this.props.formLukeB_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formLukeB_Rating"
                                    id="formLukeB_Rating-5"
                                    defaultChecked={this.props.formLukeB_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formLukeB_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formLukeB_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formLukeB_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                    : null)}

                                    {(this.props.formMercedes === true ? 
                                    <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Mercedes</h3>
                            <h3>Contreras</h3>
                            <Img src='https://pprhr.com/assets/images/team/mercedes.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formMercedesC_Rating"
                                controlId="formMercedesC_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Mercedes is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formMercedesC_Rating"
                                    id="formMercedesC_Rating-1"
                                    defaultChecked={this.props.formMercedesC_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formMercedesC_Rating"
                                    id="formMercedesC_Rating-2"
                                    defaultChecked={this.props.formMercedesC_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formMercedesC_Rating"
                                    id="formMercedesC_Rating-3"
                                    defaultChecked={this.props.formMercedesC_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formMercedesC_Rating"
                                    id="formMercedesC_Rating-4"
                                    defaultChecked={this.props.formMercedesC_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formMercedesC_Rating"
                                    id="formMercedesC_Rating-5"
                                    defaultChecked={this.props.formMercedesC_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formMercedesC_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formMercedesC_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formMercedesC_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                    : null)}
                                

                                
                                

                                {(this.props.formTracy === true ? 
                                    <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Tracy</h3>
                            <h3>Miller</h3>
                            <Img src='https://pprhr.com/assets/images/team/tracy.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formTracyM_Rating"
                                controlId="formTracyM_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Tracy is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTracyM_Rating"
                                    id="formTracyM_Rating-1"
                                    defaultChecked={this.props.formTracyM_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTracyM_Rating"
                                    id="formTracyM_Rating-2"
                                    defaultChecked={this.props.formTracyM_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTracyM_Rating"
                                    id="formTracyM_Rating-3"
                                    defaultChecked={this.props.formTracyM_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTracyM_Rating"
                                    id="formTracyM_Rating-4"
                                    defaultChecked={this.props.formTracyM_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTracyM_Rating"
                                    id="formTracyM_Rating-5"
                                    defaultChecked={this.props.formTracyM_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formTracyM_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formTracyM_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTracyM_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                 : null)}
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Team;