import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class TalentQuestions extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Executive Search & Talent Attraction Services</h4>
                                <hr />
                                
                                <Form.Group controlId="formPositions">
                                        <Form.Label className="font-weight-bold px-0" column sm={5}>
                                            What position(s) did PPR help you fill?  
                                        </Form.Label>
                                        <Form.Control
                                            name="formPositions"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formPositions || ""
                                            }
                                        />
                                </Form.Group>

                                <p className="font-weight-bold">
                                Rate the following statements on a scale of Strongly Disagree (1) to Strongly Agree (5): 
                                </p>

                                <Form.Group
                                as={Row}
                                name="formCandidates"
                                controlId="formCandidates"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR presents highly talented candidates with the right experience, skills and cultural alignment for our organization’s needs. 
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formCandidates"
                                    id="formCandidates-1"
                                    defaultChecked={this.props.formCandidates === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formCandidates"
                                    id="formCandidates-2"
                                    defaultChecked={this.props.formCandidates === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formCandidates"
                                    id="formCandidates-3"
                                    defaultChecked={this.props.formCandidates === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formCandidates"
                                    id="formCandidates-4"
                                    defaultChecked={this.props.formCandidates === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formCandidates"
                                    id="formCandidates-5"
                                    defaultChecked={this.props.formCandidates === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formEfficiency"
                                controlId="formEfficiency"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR completed our talent search with a high degree of quality and efficiency, meeting agreed timelines.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formEfficiency"
                                    id="formEfficiency-1"
                                    defaultChecked={this.props.formEfficiency === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formEfficiency"
                                    id="formEfficiency-2"
                                    defaultChecked={this.props.formEfficiency === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formEfficiency"
                                    id="formEfficiency-3"
                                    defaultChecked={this.props.formEfficiency === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formEfficiency"
                                    id="formEfficiency-4"
                                    defaultChecked={this.props.formEfficiency === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formEfficiency"
                                    id="formEfficiency-5"
                                    defaultChecked={this.props.formEfficiency === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentStakeholders"
                                controlId="formTalentStakeholders"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR is able to work well with a wide variety of stakeholders.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-1"
                                    defaultChecked={this.props.formTalentStakeholders === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-2"
                                    defaultChecked={this.props.formTalentStakeholders === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-3"
                                    defaultChecked={this.props.formTalentStakeholders === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-4"
                                    defaultChecked={this.props.formTalentStakeholders === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-5"
                                    defaultChecked={this.props.formTalentStakeholders === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentCommunications"
                                controlId="formTalentCommunications"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR is open, honest and timely with their communications.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-1"
                                    defaultChecked={this.props.formTalentCommunications === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-2"
                                    defaultChecked={this.props.formTalentCommunications === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-3"
                                    defaultChecked={this.props.formTalentCommunications === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-4"
                                    defaultChecked={this.props.formTalentCommunications === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-5"
                                    defaultChecked={this.props.formTalentCommunications === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentMethod"
                                controlId="formTalentMethod"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR suggested best practice recruiting strategies and methodologies tailored to meet our organization’s unique needs. 
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentMethod"
                                    id="formTalentMethod-1"
                                    defaultChecked={this.props.formTalentMethod === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentMethod"
                                    id="formTalentMethod-2"
                                    defaultChecked={this.props.formTalentMethod === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentMethod"
                                    id="formTalentMethod-3"
                                    defaultChecked={this.props.formTalentMethod === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentMethod"
                                    id="formTalentMethod-4"
                                    defaultChecked={this.props.formTalentMethod === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentMethod"
                                    id="formTalentMethod-5"
                                    defaultChecked={this.props.formTalentMethod === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentPriorities"
                                controlId="formTalentPriorities"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR takes time to learn about our business, people, culture, and priorities.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-1"
                                    defaultChecked={this.props.formTalentPriorities === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-2"
                                    defaultChecked={this.props.formTalentPriorities === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-3"
                                    defaultChecked={this.props.formTalentPriorities === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-4"
                                    defaultChecked={this.props.formTalentPriorities === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-5"
                                    defaultChecked={this.props.formTalentPriorities === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentExpectations"
                                controlId="formTalentExpectations"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Regarding overall value, PPR consistently meets or exceeds my expectations in providing top-tier recruiting solutions.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-1"
                                    defaultChecked={this.props.formTalentExpectations === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-2"
                                    defaultChecked={this.props.formTalentExpectations === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-3"
                                    defaultChecked={this.props.formTalentExpectations === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-4"
                                    defaultChecked={this.props.formTalentExpectations === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-5"
                                    defaultChecked={this.props.formTalentExpectations === 5}
                                />
                                </Form.Group>
                                <hr />
                                
                                <Form.Group
                                    name="formTalentRefer"
                                    as={Row}
                                controlId="formTalentRefer"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label className="font-weight-bold" column sm={5}>
                                        Based on your experience with PPR’s performance, how likely are you to engage with PPR for future projects or refer others to PPR (on a scale of 1 (Not Likely At All) to 5 (Extremely Likely)  
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                        <Form.Check
                                        inline
                                        type="radio"
                                        label="1 (not likely at all)"
                                        value="1"
                                        name="formTalentRefer"
                                        id="formTalentRefer-1"
                                        defaultChecked={
                                        this.props.formTalentRefer === 1
                                        }
                                            /></Row>
                                        <Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2 (unlikely)"
                                        value="2"
                                        name="formTalentRefer"
                                        id="formTalentRefer-2"
                                        defaultChecked={
                                        this.props.formTalentRefer === 2
                                        }                               
                                            /></Row>
                                        <Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3 (neutral)"
                                        value="3"
                                        name="formTalentRefer"
                                        id="formTalentRefer-3"
                                        defaultChecked={
                                        this.props.formTalentRefer === 3
                                        }                               
                                            /></Row>
                                        <Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4 (somewhat likely) "
                                        value="4"
                                        name="formTalentRefer"
                                        id="formTalentRefer-4"
                                        defaultChecked={
                                        this.props.formTalentRefer === 4
                                        }                               
                                            /></Row>
                                        <Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5 (Extremely Likely) "
                                        value="5"
                                        name="formTalentRefer"
                                        id="formTalentRefer-5"
                                        defaultChecked={
                                        this.props.formTalentRefer === 5
                                        }                               
                                            /></Row>
                                        </Col>
                                </Form.Group>

                                <div>
                                    {(this.props.formTalentRefer != null) && (
                                        <Form.Group controlId="formTalentReferOtherAnswer">
                                        <Form.Label className="font-weight-bold px-0" column sm={5}>
                                            Please elaborate on your selection. Your guidance, trust and feedback are appreciated. 
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentReferOtherAnswer"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentReferOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>



                                {/* <p  className="font-weight-bold px-0">If yes, we appreciate your trust and referrals.</p> */}
                                

                                <Form.Group controlId="formTalentApprove">
                                        <Form.Label className='font-weight-bold px-0' column sm={5}>
                                            Please list any services that you would like to see PPR provide more of.
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentApprove"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentApprove || ""
                                            }
                                        />
                                </Form.Group>

                                <Form.Group controlId="formTalentReasons">
                                        <Form.Label className='font-weight-bold px-0' column sm={5}>
                                            In your opinion, what are three reasons PPR was selected as (or continues to be) your expert partner? ***Please note that by completing this section, you are consenting to potential use of this quote on PPR’s social media, website, and other materials for promotional purposes. 
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentReasons"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentReasons || ""
                                            }
                                        />
                                </Form.Group>

                                {/* <Form.Group controlId="formTalentQuote">
                                        <Form.Label className='font-weight-bold px-0' column sm={5}>
                                            5. Could you please provide us a quote for us to share on our website or with future clients considering our services? 
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentQuote"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentQuote || ""
                                            }
                                        />
                                </Form.Group> */}
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default TalentQuestions;