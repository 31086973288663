import React, { Component } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';

import PersonnelFiles from './Questionnaire/Compliance/PersonnelFiles';
import OnOffBoarding from './Questionnaire/Compliance/OnOffBoarding';
import Risk from './Questionnaire/Compliance/Risk';
import EmployeeBenefits from './Questionnaire/Compliance/EmployeeBenefits';
import PerformanceManagement from './Questionnaire/Compliance/PerformanceManagement';
import Payroll from './Questionnaire/Compliance/Payroll';
import HRIS from './Questionnaire/Compliance/HRIS';
import TalentAttraction from './Questionnaire/Compliance/TalentAttraction';
import HR from './Questionnaire/Compliance/HR';

import debounce from "debounce";
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import HCA_NavBar from './HCA_NavBar';

const API_URL = config.url.API_URL;

const Div = styled.div`
  background: ${(props) => props.theme.colors.white} !important;

  .form-header {
    background: linear-gradient(
      to right top,
      #14141e,
      #181d2a,
      #1a2636,
      #193042,
      #153a4e,
      #14455b,
      #105168,
      #085d75,
      #086d88,
      #077d9c,
      #048eb0,
      #009fc4
    );
    margin-top: 1.5em;
    margin-left: 31px;
    margin-right: 31px;
    padding: 1.5em;
    color: ${(props) => props.theme.colors.orange};

    .heading-name{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .heading-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .form-section {
    border: 1px solid ${(props) => props.theme.colors.light_blue};
    padding: 2em;
    margin-top: 1em;
    border-radius: 0.2em;
  }
`;



class HCA_Compliance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            complianceSection: "hca_compliance_personnel",
            complianceSectionDisplay: "Personnel Files",

            //Personnel
            formPersonnelFiles: " ",
            formPersonnelFilesPaper: "",
            formPersonnelFilesElectronic: "",
            formPersonnelFilesStored: "",
            formPersonnelFilesAccess: "",
            formPersonnelFilesMedKept: "",
            formPersonnelFilesAuthorization: "",
            formPersonnelFilesMedAccess: "",
            formPersonnelFilesMedSeparate: "",
            formPersonnelFilesMedRetention: "",
            formPersonnelFilesSeparate: "",
            formPersonnelFilesChanges: "",
            formPersonnelFilesDesks: "",
            formPersonnelFilesRetention: "",

            formJDResponsible: "",
            formJDLegal: "",
            formJDSignatures: "",
            formJDFunctions: "",
            formJDClassification: "",
            formJDReview: "",

            //OnOffBoarding
            formOnOffResponsible: "",
            formOnOffProcedures: "",
            formOnOffTopics: "",
            formOnOffPacket: "",

            formClassExempt: "",
            formClassHours: "",
            formClassContractors: "",
            formClassPaid: "",
            formClassManages: "",
            formClassServices: "",
            formClassLength: "",
            formClassContractorHours: "",
            formClassRequired: "",
            formClassEnforcement: "",
            formClassEnforcementLabor: "",
            formClassEnforcementComplaints: "",
            formClassEnforcementLitigation: "",

            formImmigrationI9: "",
            formImmigrationManages: "",
            formImmigrationProcess: "",
            formImmigrationKept: "",
            formImmigrationRetention: "",
            formImmigrationID: "",
            formImmigrationIDRetained: "",
            formImmigrationStored: "",
            formImmigrationEVerify: "",
            formImmigrationEVerifyManage: "",
            formImmigrationEVerifyNotes: "",

            formEEO: "",
            formEEOContract: "",
            formEEOJobBank: "",
            formEEODisclaimer: "",
            formEEOData: "",
            formEEO1_Report: "",
            formEEO4212_Report: "",

            formAAPGoals: "",
            formAAPOutreach: "",


            //Risk
            formNoticesLocation: "",
            formNoticesLocationAll: "",
            formNoticesRemote: "",
            
            formADACases: "",
            formADAManaged: "",
            formADADisability: "",
            formADAProcess: "",
            formADAManager: "",
            formADAEmpRequest: "",
            formADAResponses: "",
            formADAApprovals: "",
            formADARecords: "",
            formADARetention: "",
            
            formSafety: "",
            formSafetyRisks: "",
            formSafetyHistory: "",
            formSafetyHandled: "",
            formSafetyWorkersComp: "",
            formSafetyManager: "",
            formSafetyInsurer: "",
            formSafetyResponsible: "",
            formSafetyLocation: "",
            formSafetyContent: "",
            formSafetyAccess: "",
            formSafetyRetention: "",
            formSafetyInsurance: "",

            formSafetyProcedures: "",
            formSafetyFollow: "",
            formSafetyOSHA: "",
            formSafetyOSHA300: "",
            formSafetyOSHA301: "",
            formSafetyOSHA300A: "",
            formSafetyCovid: "",
            formSafetyManual: "",
            formSafetyDrugTest: "",

            //EmployeeBenefits
            formBenefitsTypesMedical: false,
            formBenefitsTypesDental: false,
            formBenefitsTypesVision: false,
            formBenefitsTypesRX: false,
            formBenefitsTypesFSA: false,
            formBenefitsTypesHRA: false,
            formBenefitsTypesHSA: false,
            formBenefitsTypesLife: false,
            formBenefitsTypesADD: false,
            formBenefitsTypesSTD: false,
            formBenefitsTypesLTD: false,
            formBenefitsTypesRetirement: false,
            formBenefitsTypesCar: false,
            formBenefitsTypesPhone: false,
            formBenefitsTypesOther: false,
            formBenefitsTypesOtherAnswer: "",

            formBenefitsDependent: "",
            formBenefitsSupplement: "",
            formBenefitsAccess: "",
            formBenefitsBroker: "",
            formBenefitsTenure: "",
            formBenefitsService: "",
            formBenefits5500: "",
            formBenefitsBrokerFile: "",
            formBenefitsDiscrimination: "",
            formBenefitsTesting: "",
            formBenefitsDate: "",
            formBenefitsLead: "",
            formBenefitsProcess: "",
            formWelfareSchedule: "",
            formBenefitsSPD: "",
            formBenefitsPlan: "",
            formBenefitsSummary: "",
            formBenefitsContract: "",
            formCOBRAIndividual: "",
            formCOBRAProcedures: "",
            formCOBRAOversight: "",

            formPaidLeaveVacation: false,
            formPaidLeavePaidTimeOff: false,
            formPaidLeaveHoliday: false,
            formPaidLeaveSick: false,
            formPaidLeaveBereavement: false,
            formPaidLeaveJuryDuty: false,
            formPaidLeaveMedical: false,
            formPaidLeaveSabbatical: false,
            formPaidLeaveAdoption: false,
            formPaidLeaveOther: false,
            formPaidLeaveOtherAnswer: "",
            formPaidLeaveHandbook: "",
            formPaidLeaveDocumented: "",
            formPaidLeaveTracked: "",

            formUnpaidLeaveLoA: "",
            formUnpaidLeaveProcess: "",
            formUnpaidLeaveRequest: "",
            formUnpaidLeaveResponses: "",
            formUnpaidLeaveApprovals: "",
            formUnpaidLeaveFMLA: "",
            formUnpaidLeaveTracking: "",

            formRetirementTypes401k: false,
            formRetirementTypes403b: false,
            formRetirementTypesSimple: false,
            formRetirementTypesSep: false,
            formRetirementOther: false,
            formRetirementOtherAnswer: "",
            formRetirementTPA: "",
            formRetirementEmpMatch: "",
            formRetirementMatch: "",
            formRetirementVested: "",
            formRetirementEnroll: "",
            formRetirementEnrolledHow: "",
            formRetirement5500: "",
            formRetirement5500Process: "",

            formIntegrationTypesFlexHours: false,
            formIntegrationTypesRemote: false,
            formIntegrationTypesCompressed: false,
            formIntegrationTypesJobSharing: false,
            formIntegrationTypesPartTime: false,
            formIntegrationTypesSeasonal: false,
            formIntegrationTypesOther: false,
            formIntegrationTypesOtherAnswer: "",
            formIntegrationHandbook: "",
            formIntegrationDocument: "",

            formTrainingIndividuals: "",
            formTrainingMethodology: "",
            formTrainingFunction: "",
            formTrainingCompliance: "",
            formTrainingComplianceOften: "",
            formTrainingProDev: "",
            formTrainingProDevOften: "",
            formTrainingLeadership: "",
            formTrainingLeadershipOften: "",
            formTrainingSafety: "",
            formTrainingSafetyOften: "",
            formTrainingExecCoaching: "",
            formTrainingExecCoachingOften: "",
            formTrainingManagement: "",
            formTrainingManagementOften: "",
            formTrainingHarassment: "",
            formTrainingHarassmentOften: "",
            formTrainingOthers: "",

            //PerformanceManagement
            formPerformanceProcess: "",
            formPerformanceResponsible: "",
            formPerformanceTraining: "",
            formPerformanceCommunication: "",
            formPerformanceGoals: "",
            formPerformanceCorrective: "",

            //Payroll
            formPayrollResponsible: "",
            formPayrollReviewer: "",
            formPayrollProvider: "",
            formPayrollSystem: "",
            formPayrollTimeKeeping: "",
            formPayrollWeek: "",
            formPayrollSchedule: "",
            formPayrollTime: "",
            formPayrollMeals: "",

            formPayWho: "",
            formPayWhen: "",
            formPayHow: "",
            formOvertimeApproval: "",
            formOvertimeDistribution: "",
            formOvertimeLimitation: "",
            formOvertimeCompensatory: "",

            formTempResponsible: "",
            formTempRecords: "",
            formTempLength: "",
            formWithholdingTaxes: "",
            formWithholdingMedical: "",
            formWithholdingGarnishment: "",
            formWithholdingOther: "",
            formWithholdingsShift: false,
            formWithholdingsWeekend: false,
            formWithholdingsHoliday: false,
            formWithholdingsOnCall: false,
            formWithholdingsHazard: false,
            formWithholdingsBiLingual: false,
            formWithholdingsSkills: false,
            formWithholdingsOther: false,
            formWithholdingsOtherAnswer: "",

            formVariablePayCommission: false,
            formVariablePayProfit: false,
            formVariablePayReferral: false,
            formVariablePayHiring: false,
            formVariablePayRetention: false,
            formVariablePayProject: false,
            formVariablePayPerformance: false,
            formVariablePayAppreciation: false,
            formVariablePayOther: false,
            formVariablePayOtherAnswer: "",

            formVariablePayResponsible: "",
            formVariablePayApproval: "",
            formVariablePayTiming: "",
            formVariablePayBasis: "",

            formIncreasesPerformance: "",
            formIncreasesCostOfLiving: "",
            formIncreasesMarket: "",

            formStarting: "",
            formPhilosophy: "",
            formCompDecision: "",
            formCompDecisionMethod: "",
            formPromotions: "",
            formChanges: "",
            formTermination: "",

            formUnemploymentResponsible: "",
            formUnemploymentManagement: "",
            formUnemploymentAccount: "",
            formUnemploymentRespond: "",
            formUnemploymentRecords: "",

            //HRIS
            formHRISPayroll: false,
            formHRISSelf: false,
            formHRISCompany: false,
            formHRISTime: false,
            formHRISApplicant: false,
            formHRISPerformance: false,
            formHRISTracking: false,
            formHRISTraining: false,
            formHRISPersonnel: false,
            formHRISPortal: false,
            formHRISOther: false,
            formHRISOtherAnswer: "",

            //TalentAttraction
            formTAProcess: "",
            formTAAdvertising: "",
            formTAWhere: "",
            formTAWhen: "",
            formTAWho: "",
            formTARecords: "",
            formTAPositions: "",
            formTAOpen: "",
            formTAInternal: "",
            formTAOutside: "",
            formTAOutsideCompany: "",
            formTAOutsideType: "",
            formInterviewProcess: "",
            formInterviewConducts: "",
            formInterviewTrains: "",
            formInterviewOffer: "",
            formInterviewTemplate: "",
            formInterviewNonCompete: "",
            formInterviewSubmission: "",
            formInterviewSent: "",
            formInterviewAccommodation: "",
            formInterviewCompliant: "",
            formInterviewFlow: "",
            formInterviewBackground: "",
            formInterviewResponsibleInd: "",
            formInterviewManaged: "",
            formInterviewRegulatory: "",
            formInterviewCommunication: "",
            formInterviewTiming: "",
            formInterviewLanguage: "",
            formInterviewPostOffer: "",
            formInterviewOversee: "",
            formInterviewLocation: "",
            formInterviewNeeded: "",
            formInterviewWhen: "",
            formInterviewDrugTesting: "",
            formInterviewCommunicated: "",

            formTempAgencies: "",
            formTempNumber: "",
            formTempEmployment: "",
            formTempBenefits: "",

            formInterns: "",
            formInternsPaid: "",
            formInternsUnpaid: "",

            //HR
            formHRPoliciesLocation: "",
            formHRPoliciesSchedule: "",
            formHRPoliciesResponsible: "",
            
        };

        this.displayQuestionSection = this.displayQuestionSection.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

    
    componentDidMount() {
        this.updateStates();
    }

    updateStates() {
        const tableSections = [
            'hca_compliance_personnel',
            'hca_compliance_onoff',
            'hca_compliance_risk',
            'hca_compliance_benefits',
            'hca_compliance_performance',
            'hca_compliance_payroll',
            'hca_compliance_hris',
            'hca_compliance_talent',
            'hca_compliance_hr'
        ];

        for (let i = 0; i < tableSections.length; i++){
            this.grabTableData(tableSections[i]);
        }
    }

  async grabTableData (tableName) {
    await fetch(API_URL + `/compliance/responses/${tableName}/${this.props.hcaId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then((result) => {
        this.setState({
          ...result[0],
        });
        console.log("the results should be here");
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

    handleCheck(e) {
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));
    this.setState({ [e.target.name]: e.target.checked }, () => this.updateDB());
  }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => this.updateDB());
    };

    displayQuestionSection(section) {
        this.setState({ complianceSection: section }, () => this.displaySectionHeading());
    }

    displaySectionHeading() {
        if (this.state.complianceSection === "hca_compliance_personnel") {
            this.setState({complianceSectionDisplay: "Personnel Files"})
        } else if (this.state.complianceSection === "hca_compliance_onoff") {
            this.setState({complianceSectionDisplay: "On and Off-Boarding"})
        } else if (this.state.complianceSection === "hca_compliance_risk") {
            this.setState({complianceSectionDisplay: "Risk"})
        } else if (this.state.complianceSection === "hca_compliance_benefits") {
            this.setState({complianceSectionDisplay: "Employee Benefits"})
        } else if (this.state.complianceSection === "hca_compliance_performance") {
            this.setState({complianceSectionDisplay: "Performance Management"})
        } else if (this.state.complianceSection === "hca_compliance_payroll") {
            this.setState({complianceSectionDisplay: "Payroll"})
        } else if (this.state.complianceSection === "hca_compliance_hris") {
            this.setState({complianceSectionDisplay: "Human Resources Information System (HRIS)"})
        } else if (this.state.complianceSection === "hca_compliance_talent") {
            this.setState({complianceSectionDisplay: "Talent Attraction"})
        } else if (this.state.complianceSection === "hca_compliance_hr") {
            this.setState({complianceSectionDisplay: "HR Policies & Procedures"})
        } else {
            this.setState({complianceSectionDisplay: "Something is Wrong!"})
        }
    }

    updateDB = debounce(() => {
        let data = {};
        if (this.state.complianceSection === "hca_compliance_personnel") {
            data = {
                formPersonnelFiles: this.state.formPersonnelFiles,
                formPersonnelFilesPaper: this.state.formPersonnelFilesPaper,
                formPersonnelFilesElectronic: this.state.formPersonnelFilesElectronic,
                formPersonnelFilesStored: this.state.formPersonnelFilesStored,
                formPersonnelFilesAccess: this.state.formPersonnelFilesAccess,
                formPersonnelFilesMedKept: this.state.formPersonnelFilesMedKept,
                formPersonnelFilesAuthorization: this.state.formPersonnelFilesAuthorization,
                formPersonnelFilesMedAccess: this.state.formPersonnelFilesMedAccess,
                formPersonnelFilesMedSeparate: this.state.formPersonnelFilesMedSeparate,
                formPersonnelFilesMedRetention: this.state.formPersonnelFilesMedRetention,
                formPersonnelFilesSeparate: this.state.formPersonnelFilesSeparate,
                formPersonnelFilesChanges: this.state.formPersonnelFilesChanges,
                formPersonnelFilesDesks: this.state.formPersonnelFilesDesks,
                formPersonnelFilesRetention: this.state.formPersonnelFilesRetention,
                formJDResponsible: this.state.formJDResponsible,
                formJDLegal: this.state.formJDLegal,
                formJDSignatures: this.state.formJDSignatures,
                formJDFunctions: this.state.formJDFunctions,
                formJDClassification: this.state.formJDClassification,
                formJDReview: this.state.formJDReview
            }
        } else if (this.state.complianceSection === "hca_compliance_onoff") {
            data = {
                formOnOffResponsible:this.state.formOnOffResponsible,
                formOnOffProcedures:this.state.formOnOffProcedures,
                formOnOffTopics:this.state.formOnOffTopics,
                formOnOffPacket:this.state.formOnOffPacket,
                formClassExempt:this.state.formClassExempt,
                formClassHours:this.state.formClassHours,
                formClassContractors:this.state.formClassContractors,
                formClassPaid:this.state.formClassPaid,
                formClassManages:this.state.formClassManages,
                formClassServices:this.state.formClassServices,
                formClassLength:this.state.formClassLength,
                formClassContractorHours:this.state.formClassContractorHours,
                formClassRequired:this.state.formClassRequired,
                formClassEnforcement:this.state.formClassEnforcement,
                formClassEnforcementLabor:this.state.formClassEnforcementLabor,
                formClassEnforcementComplaints:this.state.formClassEnforcementComplaints,
                formClassEnforcementLitigation:this.state.formClassEnforcementLitigation,
                formImmigrationI9:this.state.formImmigrationI9,
                formImmigrationManages:this.state.formImmigrationManages,
                formImmigrationProcess:this.state.formImmigrationProcess,
                formImmigrationKept:this.state.formImmigrationKept,
                formImmigrationRetention:this.state.formImmigrationRetention,
                formImmigrationID:this.state.formImmigrationID,
                formImmigrationIDRetained:this.state.formImmigrationIDRetained,
                formImmigrationStored:this.state.formImmigrationStored,
                formImmigrationEVerify:this.state.formImmigrationEVerify,
                formImmigrationEVerifyManage:this.state.formImmigrationEVerifyManage,
                formImmigrationEVerifyNotes:this.state.formImmigrationEVerifyNotes,
                formEEO:this.state.formEEO,
                formEEOContract:this.state.formEEOContract,
                formEEOJobBank:this.state.formEEOJobBank,
                formEEODisclaimer:this.state.formEEODisclaimer,
                formEEOData:this.state.formEEOData,
                formEEO1_Report:this.state.formEEO1_Report,
                formEEO4212_Report:this.state.formEEO4212_Report,
                formAAPGoals:this.state.formAAPGoals,
                formAAPOutreach:this.state.formAAPOutreach,
            }
        } else if (this.state.complianceSection === "hca_compliance_risk") {
            data = {
                formNoticesLocation: this.state.formNoticesLocation,
                formNoticesLocationAll: this.state.formNoticesLocationAll,
                formNoticesRemote: this.state.formNoticesRemote,
                formADACases: this.state.formADACases,
                formADAManaged: this.state.formADAManaged,
                formADADisability: this.state.formADADisability,
                formADAProcess: this.state.formADAProcess,
                formADAManager: this.state.formADAManager,
                formADAEmpRequest: this.state.formADAEmpRequest,
                formADAResponses: this.state.formADAResponses,
                formADAApprovals: this.state.formADAApprovals,
                formADARecords: this.state.formADARecords,
                formADARetention: this.state.formADARetention,
                formSafety: this.state.formSafety,
                formSafetyRisks: this.state.formSafetyRisks,
                formSafetyHistory: this.state.formSafetyHistory,
                formSafetyHandled: this.state.formSafetyHandled,
                formSafetyWorkersComp: this.state.formSafetyWorkersComp,
                formSafetyManager: this.state.formSafetyManager,
                formSafetyInsurer: this.state.formSafetyInsurer,
                formSafetyResponsible: this.state.formSafetyResponsible,
                formSafetyLocation: this.state.formSafetyLocation,
                formSafetyContent: this.state.formSafetyContent,
                formSafetyAccess: this.state.formSafetyAccess,
                formSafetyRetention: this.state.formSafetyRetention,
                formSafetyInsurance: this.state.formSafetyInsurance,
                formSafetyProcedures: this.state.formSafetyProcedures,
                formSafetyFollow: this.state.formSafetyFollow,
                formSafetyOSHA: this.state.formSafetyOSHA,
                formSafetyOSHA300: this.state.formSafetyOSHA300,
                formSafetyOSHA301: this.state.formSafetyOSHA301,
                formSafetyOSHA300A: this.state.formSafetyOSHA300A,
                formSafetyCovid: this.state.formSafetyCovid,
                formSafetyManual: this.state.formSafetyManual,
                formSafetyDrugTest: this.state.formSafetyDrugTest,
            }
        } else if (this.state.complianceSection === "hca_compliance_benefits") {
            data = {
                formBenefitsTypesMedical: this.state.formBenefitsTypesMedical,
                formBenefitsTypesDental: this.state.formBenefitsTypesDental,
                formBenefitsTypesVision: this.state.formBenefitsTypesVision,
                formBenefitsTypesRX: this.state.formBenefitsTypesRX,
                formBenefitsTypesFSA: this.state.formBenefitsTypesFSA,
                formBenefitsTypesHRA: this.state.formBenefitsTypesHRA,
                formBenefitsTypesHSA: this.state.formBenefitsTypesHSA,
                formBenefitsTypesLife: this.state.formBenefitsTypesLife,
                formBenefitsTypesADD: this.state.formBenefitsTypesADD,
                formBenefitsTypesSTD: this.state.formBenefitsTypesSTD,
                formBenefitsTypesLTD: this.state.formBenefitsTypesLTD,
                formBenefitsTypesRetirement: this.state.formBenefitsTypesRetirement,
                formBenefitsTypesCar: this.state.formBenefitsTypesCar,
                formBenefitsTypesPhone: this.state.formBenefitsTypesPhone,
                formBenefitsTypesOther: this.state.formBenefitsTypesOther,
                formBenefitsTypesOtherAnswer:this.state.formBenefitsTypesOtherAnswer,

                formBenefitsDependent:this.state.formBenefitsDependent,
                formBenefitsSupplement:this.state.formBenefitsSupplement,
                formBenefitsAccess:this.state.formBenefitsAccess,
                formBenefitsBroker:this.state.formBenefitsBroker,
                formBenefitsTenure:this.state.formBenefitsTenure,
                formBenefitsService:this.state.formBenefitsService,
                formBenefits5500:this.state.formBenefits5500,
                formBenefitsBrokerFile:this.state.formBenefitsBrokerFile,
                formBenefitsDiscrimination:this.state.formBenefitsDiscrimination,
                formBenefitsTesting:this.state.formBenefitsTesting,
                formBenefitsDate:this.state.formBenefitsDate,
                formBenefitsLead:this.state.formBenefitsLead,
                formBenefitsProcess:this.state.formBenefitsProcess,
                formWelfareSchedule:this.state.formWelfareSchedule,
                formBenefitsSPD:this.state.formBenefitsSPD,
                formBenefitsPlan:this.state.formBenefitsPlan,
                formBenefitsSummary:this.state.formBenefitsSummary,
                formBenefitsContract:this.state.formBenefitsContract,
                formCOBRAIndividual:this.state.formCOBRAIndividual,
                formCOBRAProcedures:this.state.formCOBRAProcedures,
                formCOBRAOversight:this.state.formCOBRAOversight,

                formPaidLeaveVacation: this.state.formPaidLeaveVacation,
                formPaidLeavePaidTimeOff: this.state.formPaidLeavePaidTimeOff,
                formPaidLeaveHoliday: this.state.formPaidLeaveHoliday,
                formPaidLeaveSick: this.state.formPaidLeaveSick,
                formPaidLeaveBereavement: this.state.formPaidLeaveBereavement,
                formPaidLeaveJuryDuty: this.state.formPaidLeaveJuryDuty,
                formPaidLeaveMedical: this.state.formPaidLeaveMedical,
                formPaidLeaveSabbatical: this.state.formPaidLeaveSabbatical,
                formPaidLeaveAdoption: this.state.formPaidLeaveAdoption,
                formPaidLeaveOther: this.state.formPaidLeaveOther,
                formPaidLeaveOtherAnswer: this.state.formPaidLeaveOtherAnswer,
                formPaidLeaveHandbook: this.state.formPaidLeaveHandbook,
                formPaidLeaveDocumented: this.state.formPaidLeaveDocumented,
                formPaidLeaveTracked: this.state.formPaidLeaveTracked,

                formUnpaidLeaveLoA: this.state.formUnpaidLeaveLoA,
                formUnpaidLeaveProcess: this.state.formUnpaidLeaveProcess,
                formUnpaidLeaveRequest: this.state.formUnpaidLeaveRequest,
                formUnpaidLeaveResponses: this.state.formUnpaidLeaveResponses,
                formUnpaidLeaveApprovals: this.state.formUnpaidLeaveApprovals,
                formUnpaidLeaveFMLA: this.state.formUnpaidLeaveFMLA,
                formUnpaidLeaveTracking: this.state.formUnpaidLeaveTracking,

                formRetirementTypes401k: this.state.formRetirementTypes401k,
                formRetirementTypes403b: this.state.formRetirementTypes403b,
                formRetirementTypesSimple: this.state.formRetirementTypesSimple,
                formRetirementTypesSep: this.state.formRetirementTypesSep,
                formRetirementOther: this.state.formRetirementOther,
                formRetirementOtherAnswer: this.state.formRetirementOtherAnswer,
                formRetirementTPA: this.state.formRetirementTPA,
                formRetirementEmpMatch: this.state.formRetirementEmpMatch,
                formRetirementMatch: this.state.formRetirementMatch,
                formRetirementVested: this.state.formRetirementVested,
                formRetirementEnroll: this.state.formRetirementEnroll,
                formRetirementEnrolledHow: this.state.formRetirementEnrolledHow,
                formRetirement5500: this.state.formRetirement5500,
                formRetirement5500Process: this.state.formRetirement5500Process,

                formIntegrationTypesFlexHours: this.state.formIntegrationTypesFlexHours,
                formIntegrationTypesRemote: this.state.formIntegrationTypesRemote,
                formIntegrationTypesCompressed: this.state.formIntegrationTypesCompressed,
                formIntegrationTypesJobSharing: this.state.formIntegrationTypesJobSharing,
                formIntegrationTypesPartTime: this.state.formIntegrationTypesPartTime,
                formIntegrationTypesSeasonal: this.state.formIntegrationTypesSeasonal,
                formIntegrationTypesOther: this.state.formIntegrationTypesOther,
                formIntegrationTypesOtherAnswer: this.state.formIntegrationTypesOtherAnswer,
                formIntegrationHandbook: this.state.formIntegrationHandbook,
                formIntegrationDocument: this.state.formIntegrationDocument,

                formTrainingIndividuals: this.state.formTrainingIndividuals,
                formTrainingMethodology: this.state.formTrainingMethodology,
                formTrainingFunction: this.state.formTrainingFunction,
                formTrainingCompliance: this.state.formTrainingCompliance,
                formTrainingComplianceOften: this.state.formTrainingComplianceOften,
                formTrainingProDev: this.state.formTrainingProDev,
                formTrainingProDevOften: this.state.formTrainingProDevOften,
                formTrainingLeadership: this.stateformTrainingLeadership,
                formTrainingLeadershipOften: this.state.formTrainingLeadershipOften,
                formTrainingSafety: this.state.formTrainingSafety,
                formTrainingSafetyOften: this.state.formTrainingSafetyOften,
                formTrainingExecCoaching: this.state.formTrainingExecCoaching,
                formTrainingExecCoachingOften: this.state.formTrainingExecCoachingOften,
                formTrainingManagement: this.state.formTrainingManagement,
                formTrainingManagementOften: this.state.formTrainingManagementOften,
                formTrainingHarassment: this.state.formTrainingHarassment,
                formTrainingHarassmentOften: this.state.formTrainingHarassmentOften,
                formTrainingOthers: this.state.formTrainingOthers,
            }
        } else if (this.state.complianceSection === "hca_compliance_performance") {
            data = {
                formPerformanceProcess: this.state.formPerformanceProcess,
                formPerformanceResponsible: this.state.formPerformanceResponsible,
                formPerformanceTraining: this.state.formPerformanceTraining,
                formPerformanceCommunication: this.state.formPerformanceCommunication,
                formPerformanceGoals: this.state.formPerformanceGoals,
                formPerformanceCorrective: this.state.formPerformanceCorrective,
            }
        } else if (this.state.complianceSection === "hca_compliance_payroll") {
            data = {
                formPayrollResponsible: this.state.formPayrollResponsible,
                formPayrollReviewer: this.state.formPayrollReviewer,
                formPayrollProvider: this.state.formPayrollProvider,
                formPayrollSystem: this.state.formPayrollSystem,
                formPayrollTimeKeeping: this.state.formPayrollTimeKeeping,
                formPayrollWeek: this.state.formPayrollWeek,
                formPayrollSchedule: this.state.formPayrollSchedule,
                formPayrollTime: this.state.formPayrollTime,
                formPayrollMeals: this.state.formPayrollMeals,

                formPayWho: this.state.formPayWho,
                formPayWhen: this.state.formPayWhen,
                formPayHow: this.state.formPayHow,
                formOvertimeApproval: this.state.formOvertimeApproval,
                formOvertimeDistribution: this.state.formOvertimeDistribution,
                formOvertimeLimitation: this.state.formOvertimeLimitation,
                formOvertimeCompensatory: this.state.formOvertimeCompensatory,

                formTempResponsible: this.state.formTempResponsible,
                formTempRecords: this.state.formTempRecords,
                formTempLength: this.state.formTempLength,
                formWithholdingTaxes: this.state.formWithholdingTaxes,
                formWithholdingMedical: this.state.formWithholdingMedical,
                formWithholdingGarnishment: this.state.formWithholdingGarnishment,
                formWithholdingOther: this.state.formWithholdingOther,
                formWithholdingsShift: this.state.formWithholdingsShift,
                formWithholdingsWeekend: this.state.formWithholdingsWeekend,
                formWithholdingsHoliday: this.state.formWithholdingsHoliday,
                formWithholdingsOnCall: this.state.formWithholdingsOnCall,
                formWithholdingsHazard: this.state.formWithholdingsHazard,
                formWithholdingsBiLingual: this.state.formWithholdingsBiLingual,
                formWithholdingsSkills: this.state.formWithholdingsSkills,
                formWithholdingsOther: this.state.formWithholdingsOther,
                formWithholdingsOtherAnswer: this.state.formWithholdingsOtherAnswer,

                formVariablePayCommission: this.state.formVariablePayCommission,
                formVariablePayProfit: this.state.formVariablePayProfit,
                formVariablePayReferral: this.state.formVariablePayReferral,
                formVariablePayHiring: this.state.formVariablePayHiring,
                formVariablePayRetention: this.state.formVariablePayRetention,
                formVariablePayProject: this.state.formVariablePayProject,
                formVariablePayPerformance: this.state.formVariablePayPerformance,
                formVariablePayAppreciation: this.state.formVariablePayAppreciation,
                formVariablePayOther: this.state.formVariablePayOther,
                formVariablePayOtherAnswer: this.state.formVariablePayOtherAnswer,

                formVariablePayResponsible: this.state.formVariablePayResponsible,
                formVariablePayApproval: this.state.formVariablePayApproval,
                formVariablePayTiming: this.state.formVariablePayTiming,
                formVariablePayBasis: this.state.formVariablePayBasis,

                formIncreasesPerformance: this.state.formIncreasesPerformance,
                formIncreasesCostOfLiving: this.state.formIncreasesCostOfLiving,
                formIncreasesMarket: this.state.formIncreasesMarket,

                formStarting: this.state.formStarting,
                formPhilosophy: this.state.formPhilosophy,
                formCompDecision: this.state.formCompDecision,
                formCompDecisionMethod: this.state.formCompDecisionMethod,
                formPromotions: this.state.formPromotions,
                formChanges: this.state.formChanges,
                formTermination: this.state.formTermination,

                formUnemploymentResponsible: this.state.formUnemploymentResponsible,
                formUnemploymentManagement: this.state.formUnemploymentManagement,
                formUnemploymentAccount: this.state.formUnemploymentAccount,
                formUnemploymentRespond: this.state.formUnemploymentRespond,
                formUnemploymentRecords: this.state.formUnemploymentRecords,
            }
        } else if (this.state.complianceSection === "hca_compliance_hris") {
            data = {
                formHRISPayroll: this.state.formHRISPayroll,
                formHRISSelf: this.state.formHRISSelf,
                formHRISCompany: this.state.formHRISCompany,
                formHRISTime: this.state.formHRISTime,
                formHRISApplicant: this.state.formHRISApplicant,
                formHRISPerformance: this.state.formHRISPerformance,
                formHRISTracking: this.state.formHRISTracking,
                formHRISTraining: this.state.formHRISTraining,
                formHRISPersonnel: this.state.formHRISPersonnel,
                formHRISPortal: this.state.formHRISPortal,
                formHRISOther: this.state.formHRISOther,
                formHRISOtherAnswer: this.state.formHRISOtherAnswer,
            }
        } else if (this.state.complianceSection === "hca_compliance_talent") {
            data = {
                formTAProcess: this.state.formTAProcess,
                formTAAdvertising: this.state.formTAAdvertising,
                formTAWhere: this.state.formTAWhere,
                formTAWhen: this.state.formTAWhen,
                formTAWho: this.state.formTAWho,
                formTARecords: this.state.formTARecords,
                formTAPositions: this.state.formTAPositions,
                formTAOpen: this.state.formTAOpen,
                formTAInternal: this.state.formTAInternal,
                formTAOutside: this.state.formTAOutside,
                formTAOutsideCompany: this.state.formTAOutsideCompany,
                formTAOutsideType: this.state.formTAOutsideType,
                formInterviewProcess: this.state.formInterviewProcess,
                formInterviewConducts: this.state.formInterviewConducts,
                formInterviewTrains: this.state.formInterviewTrains,
                formInterviewOffer: this.state.formInterviewOffer,
                formInterviewTemplate: this.state.formInterviewTemplate,
                formInterviewNonCompete: this.state.formInterviewNonCompete,
                formInterviewSubmission: this.state.formInterviewSubmission,
                formInterviewSent: this.state.formInterviewSent,
                formInterviewAccommodation: this.state.formInterviewAccommodation,
                formInterviewCompliant: this.state.formInterviewCompliant,
                formInterviewFlow: this.state.formInterviewFlow,
                formInterviewBackground: this.state.formInterviewBackground,
                formInterviewResponsibleInd: this.state.formInterviewResponsibleInd,
                formInterviewManaged: this.state.formInterviewManaged,
                formInterviewRegulatory: this.state.formInterviewRegulatory,
                formInterviewCommunication: this.state.formInterviewCommunication,
                formInterviewTiming: this.state.formInterviewTiming,
                formInterviewLanguage: this.state.formInterviewLanguage,
                formInterviewPostOffer: this.state.formInterviewPostOffer,
                formInterviewOversee: this.state.formInterviewOversee,
                formInterviewLocation: this.state.formInterviewLocation,
                formInterviewNeeded: this.state.formInterviewNeeded,
                formInterviewWhen: this.state.formInterviewWhen,
                formInterviewDrugTesting: this.state.formInterviewDrugTesting,
                formInterviewCommunicated: this.state.formInterviewCommunicated,
                formTempAgencies: this.state.formTempAgencies,
                formTempNumber: this.state.formTempNumber,
                formTempEmployment: this.state.formTempEmployment,
                formTempBenefits: this.state.formTempBenefits,
                formInterns: this.state.formInterns,
                formInternsPaid: this.state.formInternsPaid,
                formInternsUnpaid: this.state.formInternsUnpaid,
            }
        } else if (this.state.complianceSection === "hca_compliance_hr") {
            data = {
                formHRPoliciesLocation: this.state.formHRPoliciesLocation,
                formHRPoliciesSchedule: this.state.formHRPoliciesSchedule,
                formHRPoliciesResponsible: this.state.formHRPoliciesResponsible,
            }
        }
        this.updateApiCall(this.state.complianceSection, data);
    }, 1000);

    updateApiCall(complianceSection, data) {
    if (complianceSection === "hca_compliance_personnel") {
      fetch(API_URL + `/compliance/personnel`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err))
      console.log(complianceSection);
      console.log(data)
        
    } else if (complianceSection === "hca_compliance_onoff") {
      fetch(API_URL + `/compliance/onoff`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    } else if (complianceSection === "hca_compliance_risk") {
      fetch(API_URL + `/compliance/risk`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    } else if (complianceSection === "hca_compliance_benefits") {
      fetch(API_URL + `/compliance/benefits`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    } else if (complianceSection === "hca_compliance_performance") {
      fetch(API_URL + `/compliance/performance`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    } else if (complianceSection === "hca_compliance_payroll") {
      fetch(API_URL + `/compliance/payroll`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    } else if (complianceSection === "hca_compliance_hris") {
      fetch(API_URL + `/compliance/hris`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    } else if (complianceSection === "hca_compliance_talent") {
      fetch(API_URL + `/compliance/talent`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    } else if (complianceSection === "hca_compliance_hr") {
      fetch(API_URL + `/compliance/hr`, {
        method: "PUT",
        body: JSON.stringify({
          hcaId: this.props.hcaId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(complianceSection);
      console.log(data);
    }
  }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Container>
                    <Div>
                        <HCA_NavBar displayQuestionSection={this.displayQuestionSection} />

                        <Row className="form-header">
                            <Col md="auto" className="heading-name">
                                <h4>{this.state.complianceSectionDisplay}</h4>
                            </Col>
                        </Row>
                        {this.state.complianceSection === "hca_compliance_personnel" && (<PersonnelFiles
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}
                        />)}
                        {this.state.complianceSection === "hca_compliance_onoff" && (<OnOffBoarding
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}/>)}
                        {this.state.complianceSection === "hca_compliance_risk" && (<Risk
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}
                        />)}
                        {this.state.complianceSection === "hca_compliance_benefits" && (<EmployeeBenefits
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}/>)}
                        {this.state.complianceSection === "hca_compliance_performance" && (<PerformanceManagement
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}
                        />)}
                        {this.state.complianceSection === "hca_compliance_payroll" && (<Payroll
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}/>)}
                        {this.state.complianceSection === "hca_compliance_hris" && (<HRIS
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}
                        />)}
                        {this.state.complianceSection === "hca_compliance_talent" && (<TalentAttraction
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}
                        />)}
                        {this.state.complianceSection === "hca_compliance_hr" && (<HR
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            {...this.state}/>)}
                    </Div>
                    <ScrollToTop />
                </Container>
            </ThemeProvider>
        )
    }
}

export default HCA_Compliance;