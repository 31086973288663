import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;

    .help-text{
        color: red;
        font-size: .75rem;
        padding-left: 1rem;
        font-style: italic;
    }

    .help-text-services{
        color: red;
        font-size: .75rem;
        padding-left: .2rem;
        font-style: italic;
    }
`



class IntroQuestions extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    
                        <Form className="p-3">
                            <div className="form-section">
                                <Form.Group className="mb-0 px-0" as={Col} controlId="name">
                                        <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        required
                                        name="name"
                                        onChange={this.props.handleChange}
                                        />
                                </Form.Group>
                                {
                                    this.props.name === "" ?
                                        <p className="help-text">*Name field is required</p> : <p className="help-text"></p>
                                }
                                
                                <Form.Group
                                    name="formConsultants"
                                    controlId="formConsultants"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label className='font-weight-bold'>
                                        Please select the Talent Acquisition consultant(s) from PPR whom you interviewed with. (select all that apply)
                                    </Form.Label>
                                                                        
                                        <Form.Check
                                        type="checkbox"
                                        label="Denise Maloney-Flores"
                                        name="deniseMF"
                                        id="deniseMF"
                                        defaultChecked={this.props.deniseMF}
                                        />

                                        <Form.Check
                                        type="checkbox"
                                        label="Gabriela Norton"
                                        name="gabrielaN"
                                        id="gabrielaN"
                                        defaultChecked={
                                            this.props.gabrielaN
                                        }
                                        />
                                        
                                        <Form.Check
                                        type="checkbox"
                                        label="Kathy Bennett"
                                        name="kathyB"
                                        id="kathyB"
                                        defaultChecked={
                                            this.props.kathyB
                                        }
                                        />

                                        
                                        <Form.Check
                                        type="checkbox"
                                        label="Lauren Lloyd"
                                        name="laurenL"
                                        id="laurenL"
                                        defaultChecked={this.props.laurenL}
                                        />
                                        
                                    
                                </Form.Group>
                                <hr/>
                                
                            </div>
                        </Form>
                
                </Div>
           </ThemeProvider>
        )
    }
}

export default IntroQuestions;